import { generateCloudServiceConfig, MentionCustomization } from './collaborative_editor_helpers';
import ClassicEditorBase from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';
import CollaborativeEditorWatchdog from '../collaborative_editor_watchdog';

import SingleLineEditor from '../../single_line_editor';
import Mention from '@ckeditor/ckeditor5-mention/src/mention';

import {
  ckeditorPluginList,
  ckeditorNumericFontSizeConfig,
  headingsConfig,
  initialEditorConfiguration
} from '../shared';

class ClassicEditor extends ClassicEditorBase { }
ClassicEditor.builtinPlugins = ckeditorPluginList;

export function createCollaborativeEditor(element, usersFeeds, feeds, destroyableRefs) {
  const config = createCollaborativeEditorConfig(element, usersFeeds, feeds, ckeditorNumericFontSizeConfig, headingsConfig);

    const watchdog = new CollaborativeEditorWatchdog();
    watchdog.create(element, config)
    .then(() => {
      destroyableRefs.add(watchdog);
    });
}

function createCollaborativeEditorConfig(editableElement, usersFeeds, feeds, ckeditorNumericFontSizeConfig, headingsConfig) {
  const initialData = initialEditorConfiguration(editableElement);
  return  {
    cloudServices: {
      tokenUrl: initialData.tokenUrl,
      uploadUrl: initialData.uploadUrl,
      webSocketUrl: initialData.webSocketUrl,
      bundleVersion: initialData.bundleVersion
    },
    collaboration: {
      channelId: initialData.channelId,
    },
    sidebar: {
      container: document.querySelector('.ck-collaboration__sidebar')
    },
    presenceList: {
      container: document.querySelector('.ck-collaboration__presence')
    },
    comments: {
      editorConfig: {
        extraPlugins: [Mention, MentionCustomization],
        mention: {
          feeds: usersFeeds
        },
      }
    },
    mention: {
      feeds: feeds
    },
    // Use `trackChangesOn` instead of `trackChanges` to avoid collision with native CKE config option:
    // https://ckeditor.com/docs/ckeditor5/latest/api/module_core_editor_editorconfig-EditorConfig.html#member-trackChanges
    trackChangesOn: initialData.trackChangesOn,
    disableSuggestion: initialData.disableSuggestion,
    disableTrackChangesToggle: initialData.disableTrackChangesToggle,
    readOnlyMode: initialData.readOnlyMode,
    disableComments: initialData.disableComments,
    list: {
      properties: {
        startIndex: true
      }
    },
    fontSize: ckeditorNumericFontSizeConfig,
    heading: headingsConfig
  }
};

// configuration for  CKEditor.
export function createCKEditor(scope, defaultLockID, destroyableRefs) {
  scope.querySelectorAll('textarea.ckeditor').forEach(function(element) {
    if (element.classList.contains('ckeditor--enabled')) return;
    element.classList.add('ckeditor--enabled');

    const config = generateCloudServiceConfig(element)
    const EditorCreator = this.classList.contains('single-line') ? SingleLineEditor : ClassicEditor;
    createCKEditorConfig(element, config, EditorCreator, defaultLockID, destroyableRefs)
  });
}

function createCKEditorConfig(element, config, editorCreator, defaultLockID, destroyableRefs) {
  editorCreator.create(element, config)
    .then(editor => {
      // For debugging purposes, this is useful:
      // CKEditorInspector.attach( editor );
      // attach this to the editor so it makes it easier to know which editor we are using when inspecting the DOM
      this.setAttribute('data-ckeditorCreator', `textarea-${EditorCreator.name}`);

      editor.model.document.on('change:data', function () {
        const newValue = editor.getData();
        element.innerText = newValue;
        if (element.dataSet.onchangeevent) {
          const event = new Event('change');
          element.dispatchEvent(event);
        }
      })

      if (element.classList.contains('readonly')) {
        editor.enableReadOnlyMode(defaultLockID);
      }
      element.dispatchEvent(new CustomEvent('ckeditor:initialized', { bubbles: true, detail: { editor: editor } }))

      destroyableRefs.add(editor);
    })
    .catch(error => {
      console.log(error);
    })
}
