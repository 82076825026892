// Basic Toolbar plugins.
import Essentials from '@ckeditor/ckeditor5-essentials/src/essentials';
import AutoformatPlugin from '@ckeditor/ckeditor5-autoformat/src/autoformat';
import BoldPlugin from '@ckeditor/ckeditor5-basic-styles/src/bold';
import BlockQuotePlugin from '@ckeditor/ckeditor5-block-quote/src/blockquote';
import ItalicPlugin from '@ckeditor/ckeditor5-basic-styles/src/italic';
import Strikethrough from '@ckeditor/ckeditor5-basic-styles/src/strikethrough'
import Underline from '@ckeditor/ckeditor5-basic-styles/src/underline';
import Subscript from '@ckeditor/ckeditor5-basic-styles/src/subscript';
import Superscript from '@ckeditor/ckeditor5-basic-styles/src/superscript';
import HeadingPlugin from '@ckeditor/ckeditor5-heading/src/heading';
import LinkPlugin from '@ckeditor/ckeditor5-link/src/link';
import ListProperties from '@ckeditor/ckeditor5-list/src/listproperties';
import ParagraphPlugin from '@ckeditor/ckeditor5-paragraph/src/paragraph';
import Mention from '@ckeditor/ckeditor5-mention/src/mention';
import Table from '@ckeditor/ckeditor5-table/src/table';
import TableToolbar from '@ckeditor/ckeditor5-table/src/tabletoolbar';
import TableProperties from '@ckeditor/ckeditor5-table/src/tableproperties';
import TableCellProperties from '@ckeditor/ckeditor5-table/src/tablecellproperties';
import TableColumnResize from '@ckeditor/ckeditor5-table/src/tablecolumnresize';
import Indent from '@ckeditor/ckeditor5-indent/src/indent';
import IndentBlock from '@ckeditor/ckeditor5-indent/src/indentblock';
import RemoveFormat from '@ckeditor/ckeditor5-remove-format/src/removeformat';
import Font from '@ckeditor/ckeditor5-font/src/font';
import Highlight from '@ckeditor/ckeditor5-highlight/src/highlight';
import HorizontalLine from '@ckeditor/ckeditor5-horizontal-line/src/horizontalline';
import PasteFromOffice from '@ckeditor/ckeditor5-paste-from-office/src/pastefromoffice';
import Alignment from '@ckeditor/ckeditor5-alignment/src/alignment';
import PageBreak from '@ckeditor/ckeditor5-page-break/src/pagebreak';
import UploadAdapterPlugin from '@ckeditor/ckeditor5-adapter-ckfinder/src/uploadadapter';
import EasyImagePlugin from '@ckeditor/ckeditor5-easy-image/src/easyimage';
import ImagePlugin from '@ckeditor/ckeditor5-image/src/image';
import ImageCaptionPlugin from '@ckeditor/ckeditor5-image/src/imagecaption';
import ImageStylePlugin from '@ckeditor/ckeditor5-image/src/imagestyle';
import ImageToolbarPlugin from '@ckeditor/ckeditor5-image/src/imagetoolbar';
import ImageUploadPlugin from '@ckeditor/ckeditor5-image/src/imageupload';
import ImageResize from '@ckeditor/ckeditor5-image/src/imageresize';
import ImageTextAlternative from '@ckeditor/ckeditor5-image/src/imagetextalternative';
import Comments from '@ckeditor/ckeditor5-comments/src/comments';
import TrackChanges from '@ckeditor/ckeditor5-track-changes/src/trackchanges';
import TrackChangesData from '@ckeditor/ckeditor5-track-changes/src/trackchangesdata';
import FindAndReplace from '@ckeditor/ckeditor5-find-and-replace/src/findandreplace';
import WProofreader from '@webspellchecker/wproofreader-ckeditor5/src/wproofreader';
import TableCaption from '@ckeditor/ckeditor5-table/src/tablecaption';
import CodeBlock from '@ckeditor/ckeditor5-code-block/src/codeblock';


// Autosave plugin
import AutoSave from '@ckeditor/ckeditor5-autosave/src/autosave';
import { MentionCustomization } from '../account/ckeditor/shared';

// Collaboration plugins
import CloudServices from '@ckeditor/ckeditor5-cloud-services/src/cloudservices';
import RealTimeCollaborativeEditing from '@ckeditor/ckeditor5-real-time-collaboration/src/realtimecollaborativeediting';
import PresenceList from '@ckeditor/ckeditor5-real-time-collaboration/src/presencelist';
import RealTimeCollaborativeComments from '@ckeditor/ckeditor5-real-time-collaboration/src/realtimecollaborativecomments';
import RealTimeCollaborativeTrackChanges from '@ckeditor/ckeditor5-real-time-collaboration/src/realtimecollaborativetrackchanges';

// Determines the toolbar for the editor. Order does matter here.
export const toolbar = [
  'bold',
  'italic',
  'underline',
  'strikethrough',
  '|',
  'bulletedList',
  'numberedList',
  'comment',
  'trackChanges',
  '|',
  'horizontalLine',
  'pageBreak',
  '|',
  'fontSize',
  'fontColor',
  'fontBackgroundColor',
  '|',
  'insertTable',
  'imageUpload',
  '|',
  'outdent',
  'indent',
  '|',
  'highlight',
  'findAndReplace',
  '|',
  'alignment',
  'removeFormat',
  '|',
  'undo',
  'redo',
  '|',
  'blockQuote',
  'subscript',
  'superscript',
  'link',
  'wproofreader',
  'codeBlock'
];

// All of the plugins included in document view editor.
export const plugins = [
  Essentials,
  AutoformatPlugin,
  BoldPlugin,
  BlockQuotePlugin,
  ItalicPlugin,
  Strikethrough,
  Underline,
  Subscript,
  Superscript,
  HeadingPlugin,
  LinkPlugin,
  ListProperties,
  ParagraphPlugin,
  Mention,
  Table,
  TableToolbar,
  TableProperties,
  TableCellProperties,
  TableColumnResize,
  Indent,
  IndentBlock,
  RemoveFormat,
  Font,
  Highlight,
  HorizontalLine,
  PasteFromOffice,
  Alignment,
  PageBreak,
  AutoSave,
  CloudServices,
  RealTimeCollaborativeEditing,
  PresenceList,
  RealTimeCollaborativeComments,
  RealTimeCollaborativeTrackChanges,
  UploadAdapterPlugin,
  EasyImagePlugin,
  ImagePlugin,
  ImageCaptionPlugin,
  ImageStylePlugin,
  ImageToolbarPlugin,
  ImageUploadPlugin,
  ImageResize,
  ImageTextAlternative,
  FindAndReplace,
  Comments,
  TrackChanges,
  TrackChangesData,
  WProofreader,
  CodeBlock,
  MentionCustomization,
  TableCaption
];
