// CKEditor 5 plugins.
import EssentialsPlugin from '@ckeditor/ckeditor5-essentials/src/essentials';
import UploadAdapterPlugin from '@ckeditor/ckeditor5-adapter-ckfinder/src/uploadadapter';
import AutoformatPlugin from '@ckeditor/ckeditor5-autoformat/src/autoformat';
import BoldPlugin from '@ckeditor/ckeditor5-basic-styles/src/bold';
import BlockQuotePlugin from '@ckeditor/ckeditor5-block-quote/src/blockquote';
import ItalicPlugin from '@ckeditor/ckeditor5-basic-styles/src/italic';
import Strikethrough from '@ckeditor/ckeditor5-basic-styles/src/strikethrough'
import Underline from '@ckeditor/ckeditor5-basic-styles/src/underline';
import Subscript from '@ckeditor/ckeditor5-basic-styles/src/subscript';
import Superscript from '@ckeditor/ckeditor5-basic-styles/src/superscript';
import EasyImagePlugin from '@ckeditor/ckeditor5-easy-image/src/easyimage';
import HeadingPlugin from '@ckeditor/ckeditor5-heading/src/heading';
import ImagePlugin from '@ckeditor/ckeditor5-image/src/image';
import ImageCaptionPlugin from '@ckeditor/ckeditor5-image/src/imagecaption';
import ImageStylePlugin from '@ckeditor/ckeditor5-image/src/imagestyle';
import ImageToolbarPlugin from '@ckeditor/ckeditor5-image/src/imagetoolbar';
import ImageUploadPlugin from '@ckeditor/ckeditor5-image/src/imageupload';
import ImageResize from '@ckeditor/ckeditor5-image/src/imageresize';
import ImageTextAlternative from '@ckeditor/ckeditor5-image/src/imagetextalternative'
import LinkPlugin from '@ckeditor/ckeditor5-link/src/link';
import ListProperties from '@ckeditor/ckeditor5-list/src/listproperties';
import ParagraphPlugin from '@ckeditor/ckeditor5-paragraph/src/paragraph';
import Mention from '@ckeditor/ckeditor5-mention/src/mention';
import Table from '@ckeditor/ckeditor5-table/src/table';
import TableColumnResize from '@ckeditor/ckeditor5-table/src/tablecolumnresize';
import TableToolbar from '@ckeditor/ckeditor5-table/src/tabletoolbar';
import TableProperties from '@ckeditor/ckeditor5-table/src/tableproperties';
import TableCellProperties from '@ckeditor/ckeditor5-table/src/tablecellproperties';
import Indent from '@ckeditor/ckeditor5-indent/src/indent';
import IndentBlock from '@ckeditor/ckeditor5-indent/src/indentblock';
import RemoveFormat from '@ckeditor/ckeditor5-remove-format/src/removeformat';
import Font from '@ckeditor/ckeditor5-font/src/font';
import HorizontalLine from '@ckeditor/ckeditor5-horizontal-line/src/horizontalline';
import PasteFromOffice from '@ckeditor/ckeditor5-paste-from-office/src/pastefromoffice';
import Alignment from '@ckeditor/ckeditor5-alignment/src/alignment';
import PageBreak from '@ckeditor/ckeditor5-page-break/src/pagebreak';
import FindAndReplace from '@ckeditor/ckeditor5-find-and-replace/src/findandreplace';
import WProofreader from '@webspellchecker/wproofreader-ckeditor5/src/wproofreader';
import TableCaption from '@ckeditor/ckeditor5-table/src/tablecaption';
import CodeBlock from '@ckeditor/ckeditor5-code-block/src/codeblock';
import Highlight from '@ckeditor/ckeditor5-highlight/src/highlight';

// Added for cke v26
import CloudServicesPlugin from '@ckeditor/ckeditor5-cloud-services/src/cloudservices';
import { MentionCustomization } from './helpers/collaborative_editor_helpers';

export const ckeditorPluginList = [
  EssentialsPlugin,
  UploadAdapterPlugin,
  AutoformatPlugin,
  BoldPlugin,
  ItalicPlugin,
  Strikethrough,
  Underline,
  BlockQuotePlugin,
  EasyImagePlugin,
  CloudServicesPlugin,
  HeadingPlugin,
  ImagePlugin,
  ImageCaptionPlugin,
  ImageStylePlugin,
  ImageToolbarPlugin,
  ImageUploadPlugin,
  ImageResize,
  ImageTextAlternative,
  LinkPlugin,
  ListProperties,
  ParagraphPlugin,
  Mention,
  MentionCustomization,
  Table,
  TableToolbar,
  TableProperties,
  TableCellProperties,
  TableColumnResize,
  Indent,
  IndentBlock,
  RemoveFormat,
  Font,
  HorizontalLine,
  PasteFromOffice,
  Alignment,
  PageBreak,
  Subscript,
  Superscript,
  FindAndReplace,
  WProofreader,
  TableCaption,
  CodeBlock,
  Highlight
];

export const ckeditorToolbar = {
  items: [
    'heading',
    'fontSize',
    {
      label: 'Text Styles',
      icon: 'bold',
      items: [
        'bold',
        'italic',
        'underline',
        'strikethrough',
        'subscript',
        'superscript',
        'removeFormat',
      ]
    },
    'link',
    'bulletedList',
    'numberedList',
    'blockQuote',
    'horizontalLine',
    'alignment',
    'pageBreak',
    '|',
    'fontColor',
    'fontBackgroundColor',
    '|',
    'insertTable',
    'imageUpload',
    '|',
    'outdent',
    'indent',
    '|',
    'findAndReplace',
    '|',
    'undo',
    'redo',
    'wproofreader',
    'codeblock',
  ]
};

export const newCkeditorToolbar = {
  items: [
    'undo',
    'redo',
    '|',
    'heading',
    '|',
    'fontSize',
    {
      label: 'Text Styles',
      icon: 'bold',
      items: [
        'bold',
        'strikethrough',
        'subscript',
        'superscript'
      ]
    },
    'fontColor',
    'italic',
    'underline',
    'fontBackgroundColor',
    '|',
    'alignment',
    'bulletedList',
    'numberedList',
    'outdent',
    'indent',
    'pageBreak',
    '|',
    'link',
    'imageUpload',
    'insertTable',
    '|',
    'findAndReplace',
    'removeFormat',
    'wproofreader',
    'blockQuote',
    'horizontalLine',
    'commentsArchive'
  ]
};


export const ckeditorTable = {
  contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells', 'tableProperties', 'tableCellProperties', 'toggleTableCaption'],
  defaultHeadings: { rows: 1 },
  tableProperties: {
    defaultProperties: {
      backgroundColor: 'hsl(0, 0%, 100%)',
      borderStyle: 'solid',
      borderColor: 'gray',
      borderWidth: '2px',
      alignment: 'center',
      width: '',
      height: '',
    }
  },
  tableCellProperties: {
    defaultProperties: {
      borderStyle: 'solid',
      borderColor: 'gray',
      borderWidth: '2px',
      backgroundColor: 'hsl(0, 0%, 100%)',
      width: '',
      height: '',
      padding: ''
    }
  }
};

export const ckeditorImage = {
  resizeUnit: 'px',
  toolbar: [
    'imageStyle:inline',
    'imageStyle:wrapText',
    'imageStyle:breakText',
    '|',
    'imageTextAlternative',
    'toggleImageCaption',
  ],
  styles: [
    'alignLeft',
    'alignCenter',
    'alignRight'
  ]
};

export const ckeditorNumericFontSizeConfig = {
  options: [10,12,14,'16 (default)',18,20,24,36],
}

export const headingsConfig = {
  options: [
    { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
    { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
    { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
    { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
    { model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading4' },
    { model: 'heading5', view: 'h5', title: 'Heading 5', class: 'ck-heading_heading5' },
    { model: 'heading6', view: 'h6', title: 'Heading 6', class: 'ck-heading_heading6' }
  ]
}
export const forceEnabledCommandsForTrackChanges = [
  "listStyle",
  "tableBorderColor",
  "tableBorderStyle",
  "tableBorderWidth",
  "tableAlignment",
  "tableWidth",
  "tableHeight",
  "tableBackgroundColor",
  "tableCellBorderStyle",
  "tableCellBorderColor",
  "tableCellBorderWidth",
  'tableCellHorizontalAlignment',
  'tableCellWidth',
  'tableCellHeight',
  'tableCellPadding',
  'tableCellBackgroundColor',
  'tableCellVerticalAlignment',
  'listStart'
]

export const codeBlockConfig = {
  languages: [
    { language: 'ruby', label: 'Ruby', class: 'ruby'},
    { language: 'plaintext', label: 'Plain text', class: '' },
    { language: 'javascript', label: 'JavaScript', class: 'js javascript js-code' },
    { language: 'python', label: 'Python', class: 'python' },
    { language: 'bash', label: 'Bash', class: 'bash' }
  ]
};

export const wproofreaderConfig = {
  serviceId: process.env.WPROOFREADER_SERVICE_ID,
  srcUrl: process.env.WPROOFREADER_SERVICE_URL
};

export const configuration = {
  extraPlugins: [allowNestedTables],
  list: {
    properties: {
      startIndex: true
    }
  },
  link: {
    defaultProtocol: 'https://'
  },
  licenseKey: process.env.CKE_LICENSE_KEY,
  toolbar: ckeditorToolbar,
  table: ckeditorTable,
  image: ckeditorImage,
  indentBlock: {
    offset: 1,
    unit: 'em'
  },
  fontSize: ckeditorNumericFontSizeConfig,
  language: 'en',
  wproofreader: wproofreaderConfig,
  codeBlock: codeBlockConfig
};

export const newConfiguration = {
  extraPlugins: [allowNestedTables],
  list: {
    properties: {
      startIndex: true
    }
  },
  link: {
    defaultProtocol: 'https://'
  },
  licenseKey: process.env.CKE_LICENSE_KEY,
  toolbar: newCkeditorToolbar,
  table: ckeditorTable,
  image: ckeditorImage,
  indentBlock: {
    offset: 1,
    unit: 'em'
  },
  fontSize: ckeditorNumericFontSizeConfig,
  language: 'en',
  wproofreader: wproofreaderConfig,
  codeBlock: codeBlockConfig
};

export function allowNestedTables(editor) {
  editor.model.schema.on('checkChild', (evt, args) => {
    const context = args[0];
    const childDefinition = args[1];

    if (context.endsWith('tableCell') && childDefinition && childDefinition.name == 'table') {
      evt.stop();
      evt.return = true;
    }
  }, {
    priority: 'highest'
  });
};

export function initialEditorConfiguration(editableElement) {
  return {
    tokenUrl: editableElement.dataset.ckeditortokenurl,
    uploadUrl: editableElement.dataset.ckeditoruploadurl,
    webSocketUrl: editableElement.dataset.ckeditorwebsocketurl,
    bundleVersion: editableElement.dataset.ckeditorversion,
    channelId: editableElement.dataset.ckeditordocumentid,
    trackChangesOn: editableElement.dataset.ckeditortrackchangeson === 'true',
    disableSuggestion: editableElement.dataset.ckeditordisablesuggestion === 'true',
    disableTrackChangesToggle: editableElement.dataset.ckeditordisabletrackchangestoggle === 'true',
    readOnlyMode: editableElement.dataset.ckeditorreadonlymode === 'true',
    disableComments: editableElement.dataset.ckeditordisablecomments === 'true',
  }
}
