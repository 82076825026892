// Used for the multi-root editor.
import Editor from '@ckeditor/ckeditor5-core/src/editor/editor';

import MultirootEditorUI from '../ckeditor/multiroot_editor_ui';
import MultirootEditorUIView from '../ckeditor/multiroot_editor_ui_view';
import { multiRootPluginList } from '../ckeditor/shared';

import DataApiMixin from '@ckeditor/ckeditor5-core/src/editor/utils/dataapimixin';
import HtmlDataProcessor from '@ckeditor/ckeditor5-engine/src/dataprocessor/htmldataprocessor';
import getDataFromElement from '@ckeditor/ckeditor5-utils/src/dom/getdatafromelement';
import setDataInElement from '@ckeditor/ckeditor5-utils/src/dom/setdatainelement';
import mix from '@ckeditor/ckeditor5-utils/src/mix';

export default class MultirootEditor extends Editor {
	constructor( sourceElements, config ) {
		super( config );
    if (this.config.get('initialData') === undefined) {
      // Create initial data object containing data from all roots.
      const initialData = {};

      for (const rootName of Object.keys(sourceElements)) {
        initialData[rootName] = getDataFromElement(sourceElements[rootName]);
      }

      this.config.set('initialData', initialData);
    }
		this.data.processor = new HtmlDataProcessor( this.data.viewDocument );
		// Create root and UIView element for each editable container.
		for ( const rootName of Object.keys( sourceElements ) ) {
			this.model.document.createRoot( '$root', rootName );
		}
		this.ui = new MultirootEditorUI( this, new MultirootEditorUIView( this.locale, this.editing.view, sourceElements ) );
	}

	destroy() {
		// Cache the data and editable DOM elements, then destroy.
		// It's safe to assume that the model->view conversion will not work after super.destroy(),
		// same as `ui.getEditableElement()` method will not return editables.
		const data = {};
		const editables = {};
		const editablesNames = Array.from( this.ui.getEditableElementsNames() );

		for ( const rootName of editablesNames ) {
			data[ rootName ] = this.getData( {
				rootName
			} );
			editables[ rootName ] = this.ui.getEditableElement( rootName );
		}

		this.ui.destroy();

		return super.destroy()
			.then( () => {
				for ( const rootName of editablesNames ) {
					setDataInElement( editables[ rootName ], data[ rootName ] );
				}
			} );
	}

	static create( sourceElements, config ) {
		return new Promise( resolve => {
			const editor = new this( sourceElements, config );
			resolve(
				editor.initPlugins()
				.then( () => editor.ui.init() )
				.then( () => {
          const initialData = editor.config.get('initialData');

          return editor.data.init(initialData);

				} )
				.then( () => editor.fire( 'ready' ) )
				.then( () => editor )
			);
		} );
	}
}

mix( MultirootEditor, DataApiMixin );

MultirootEditor.builtinPlugins = multiRootPluginList