
// CKEditor 5 plugins.
import EssentialsPlugin from '@ckeditor/ckeditor5-essentials/src/essentials';
import UploadAdapterPlugin from '@ckeditor/ckeditor5-adapter-ckfinder/src/uploadadapter';
import AutoformatPlugin from '@ckeditor/ckeditor5-autoformat/src/autoformat';
import BoldPlugin from '@ckeditor/ckeditor5-basic-styles/src/bold';
import BlockQuotePlugin from '@ckeditor/ckeditor5-block-quote/src/blockquote';
import ItalicPlugin from '@ckeditor/ckeditor5-basic-styles/src/italic';
import Strikethrough from '@ckeditor/ckeditor5-basic-styles/src/strikethrough'
import Underline from '@ckeditor/ckeditor5-basic-styles/src/underline';
import Subscript from '@ckeditor/ckeditor5-basic-styles/src/subscript';
import Superscript from '@ckeditor/ckeditor5-basic-styles/src/superscript';
import EasyImagePlugin from '@ckeditor/ckeditor5-easy-image/src/easyimage';
import HeadingPlugin from '@ckeditor/ckeditor5-heading/src/heading';
import ImagePlugin from '@ckeditor/ckeditor5-image/src/image';
import ImageCaptionPlugin from '@ckeditor/ckeditor5-image/src/imagecaption';
import ImageStylePlugin from '@ckeditor/ckeditor5-image/src/imagestyle';
import ImageToolbarPlugin from '@ckeditor/ckeditor5-image/src/imagetoolbar';
import ImageUploadPlugin from '@ckeditor/ckeditor5-image/src/imageupload';
import ImageResize from '@ckeditor/ckeditor5-image/src/imageresize';
import ImageTextAlternative from '@ckeditor/ckeditor5-image/src/imagetextalternative'
import LinkPlugin from '@ckeditor/ckeditor5-link/src/link';
import ListProperties from '@ckeditor/ckeditor5-list/src/listproperties';
import ParagraphPlugin from '@ckeditor/ckeditor5-paragraph/src/paragraph';
import Mention from '@ckeditor/ckeditor5-mention/src/mention';
import Table from '@ckeditor/ckeditor5-table/src/table';
import TableColumnResize from '@ckeditor/ckeditor5-table/src/tablecolumnresize';
import TableToolbar from '@ckeditor/ckeditor5-table/src/tabletoolbar';
import TableProperties from '@ckeditor/ckeditor5-table/src/tableproperties';
import TableCellProperties from '@ckeditor/ckeditor5-table/src/tablecellproperties';
import Indent from '@ckeditor/ckeditor5-indent/src/indent';
import IndentBlock from '@ckeditor/ckeditor5-indent/src/indentblock';
import RemoveFormat from '@ckeditor/ckeditor5-remove-format/src/removeformat';
import Font from '@ckeditor/ckeditor5-font/src/font';
import Highlight from '@ckeditor/ckeditor5-highlight/src/highlight';
import HorizontalLine from '@ckeditor/ckeditor5-horizontal-line/src/horizontalline';
import PasteFromOffice from '@ckeditor/ckeditor5-paste-from-office/src/pastefromoffice';
import Alignment from '@ckeditor/ckeditor5-alignment/src/alignment';
import PageBreak from '@ckeditor/ckeditor5-page-break/src/pagebreak';
import FindAndReplace from '@ckeditor/ckeditor5-find-and-replace/src/findandreplace';
import WProofreader from '@webspellchecker/wproofreader-ckeditor5/src/wproofreader';
import TableCaption from '@ckeditor/ckeditor5-table/src/tablecaption';
import CodeBlock from '@ckeditor/ckeditor5-code-block/src/codeblock';


// Added for cke v26
import CloudServicesPlugin from '@ckeditor/ckeditor5-cloud-services/src/cloudservices';

export function MentionCustomization(editor) {
	// The upcast converter will convert view <a class="mention" href="" data-user-id="">
	// elements to the model 'mention' text attribute.
	editor.conversion.for('upcast').elementToAttribute({
		view: {
			name: 'span',
			key: 'data-mention',
			classes: 'mention',
			attributes: {
				'data-model': true,
				'data-id': true,
				'data-label-method': true,
				'data-label': true
			}
		},
		model: {
			key: 'mention',
			value: viewItem => {
				// The mention feature expects that the mention attribute value
				// in the model is a plain object with a set of additional attributes.
				// In order to create a proper object use the toMentionAttribute() helper method:
				const mentionAttribute = editor.plugins.get('Mention').toMentionAttribute(viewItem, {
					// Add any other properties that you need.
					modelName: viewItem.getAttribute('data-model'),
					modelId: viewItem.getAttribute('data-id'),
					labelMethod: viewItem.getAttribute('data-label-method'),
					label: viewItem.getAttribute('data-label'),
					dataToggle: viewItem.getAttribute('data-toggle'),
					dataTitle: viewItem.getAttribute('data-original-title'),
				});

				return mentionAttribute;
			}
		},
		converterPriority: 'high'
	});

	// Downcast the model 'mention' text attribute to a view <a> element.
	editor.conversion.for('downcast').attributeToElement({
		model: 'mention',
		view: (modelAttributeValue, { writer }) => {
			// Do not convert empty attributes (lack of value means no mention).
			if (!modelAttributeValue) {
				return;
			}

			// This is a hack because I couldn't find an after_select event to hook on to. Surely there is one??
			setTimeout(function () {
				$('[data-toggle="tooltip"]').tooltip();
			}, 500);

			return writer.createAttributeElement('span', {
				'class': 'mention',
				'data-model': modelAttributeValue.modelName,
				'data-id': modelAttributeValue.modelId || crypto.randomUUID(),
				'data-label': modelAttributeValue.label,
				'data-label-method': modelAttributeValue.labelMethod,
				'data-toggle': modelAttributeValue.dataToggle,
				'data-original-title': modelAttributeValue.dataTitle,
				'data-mentionings--render-target': 'mentioning',
				'data-container': '.element-box',
			}, {
				// source: https://ckeditor.com/docs/ckeditor5/latest/features/mentions.html#customizing-the-output
				// Make mention attribute to be wrapped by other attribute elements.
				priority: 20,
				// Prevent merging mentions together.
				id: modelAttributeValue.uid
			});
		},
		converterPriority: 'high'
	});
}

export const ckeditorPluginList = [
	EssentialsPlugin,
	UploadAdapterPlugin,
	AutoformatPlugin,
	BoldPlugin,
	ItalicPlugin,
	Strikethrough,
	Underline,
	BlockQuotePlugin,
	EasyImagePlugin,
	CloudServicesPlugin,
	HeadingPlugin,
	ImagePlugin,
	ImageCaptionPlugin,
	ImageStylePlugin,
	ImageToolbarPlugin,
	ImageUploadPlugin,
	ImageResize,
	ImageTextAlternative,
	LinkPlugin,
	ListProperties,
	ParagraphPlugin,
	Mention,
	MentionCustomization,
	Table,
	TableToolbar,
	TableProperties,
	TableCellProperties,
	TableColumnResize,
	Indent,
	IndentBlock,
	RemoveFormat,
	Font,
	Highlight,
	HorizontalLine,
	PasteFromOffice,
	Alignment,
	PageBreak,
	Subscript,
	Superscript,
	FindAndReplace,
	WProofreader,
	TableCaption,
	CodeBlock
];

export const multiRootPluginList = [
	EssentialsPlugin,
	UploadAdapterPlugin,
	AutoformatPlugin,
	BoldPlugin,
	ItalicPlugin,
	Strikethrough,
	Underline,
	BlockQuotePlugin,
	EasyImagePlugin,
	CloudServicesPlugin,
	HeadingPlugin,
	ImagePlugin,
	ImageCaptionPlugin,
	ImageStylePlugin,
	ImageToolbarPlugin,
	ImageUploadPlugin,
	ImageResize,
	ImageTextAlternative,
	LinkPlugin,
	ListProperties,
	ParagraphPlugin,
	Mention,
	MentionCustomization,
	Table,
	TableToolbar,
	TableProperties,
	TableCellProperties,
	Indent,
	IndentBlock,
	RemoveFormat,
	Font,
	Highlight,
	HorizontalLine,
	PasteFromOffice,
	Alignment,
	PageBreak,
	Subscript,
	Superscript,
	FindAndReplace,
	WProofreader,
	TableCaption,
	CodeBlock
];

export const ckeditorToolbar = {
	items: [
		'heading',
		'fontSize',
		{
			label: 'Text Styles',
			icon: 'bold',
			items: [
				'bold',
				'italic',
				'underline',
				'strikethrough',
				'subscript',
				'superscript',
				'removeFormat',
			]
		},
		'link',
		'bulletedList',
		'numberedList',
		'blockQuote',
		'horizontalLine',
		'alignment',
		'pageBreak',
		'|',
		'fontColor',
		'fontBackgroundColor',
		'|',
		'insertTable',
		'imageUpload',
		'|',
		'outdent',
		'indent',
		'|',
		'highlight',
		'findAndReplace',
		'|',
		'undo',
		'redo',
		'wproofreader',
		'codeBlock'
	]
};

export const ckeditorTable = {
	contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells', 'tableProperties', 'tableCellProperties', 'toggleTableCaption'],
	// https://ckeditor.com/docs/ckeditor5/latest/features/table.html#default-table-and-table-cell-styles
	// In order to make every inserted table to have n number of rows
	// and columns as table headers by default, set an optional table
	// config property defaultHeadings.
	defaultHeadings: { rows: 1 },
	tableProperties: {
		defaultProperties: {
			backgroundColor: 'hsl(0, 0%, 100%)',
			borderStyle: 'solid',
			borderColor: '#000000',
			borderWidth: '2px',
			alignment: 'center',
			width: '600px',
			height: '150px'
		}
	},
	tableCellProperties: {
		defaultProperties: {
			borderStyle: 'solid',
			borderColor: '#000000',
			borderWidth: '1px',
			backgroundColor: 'hsl(0, 0%, 100%)',
			width: '14px',
			height: '14px',
			padding: '10px'
		}
	}
};

export const ckeditorImage = {
	// Use pixel instead of % so that the resized image looks the same as the one in the editor to the user
	// See https://ckeditor.com/docs/ckeditor5/latest/features/image.html#using-pixels-instead-of-percentage-width
	resizeUnit: 'px',
	toolbar: [
		'imageStyle:inline',
		// A dropdown containing `alignLeft` and `alignRight` options.
		'imageStyle:wrapText',
		// A dropdown containing `alignBlockLeft`, `block` (default) and  `alignBlockRight` options.
		'imageStyle:breakText',
		'|',
		'imageTextAlternative',
		'toggleImageCaption',
	],
	styles: [
		'alignLeft',
		'alignCenter',
		'alignRight'
	]
};


export const ckeditorNumericFontSizeConfig = {
	// We want consistent font size dropdowns across all ck editor instances (CIT-2882),
	// For compliance reaonons (see CIT-2881) we want to limit selections to no smaller than 16px so that we have 12pt sizes in PDFs.
	// see https://ckeditor.com/docs/ckeditor5/latest/features/font.html#using-the-predefined-presets for info on these options.
	options: [
		6,
		7,
		8,
		9,
		10,
		12,
		14,
		'16 (default)',
		18,
		20,
		24,
		36
	],
}

export const headingsConfig = {
	options: [
		{ model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
		{ model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
		{ model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
		{ model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading2' },
		{ model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading2' },
		{ model: 'heading5', view: 'h5', title: 'Heading 5', class: 'ck-heading_heading2' },
		{ model: 'heading6', view: 'h6', title: 'Heading 6', class: 'ck-heading_heading2' }

	]
}

export const codeBlockConfig = {
	languages: [
			{ language: 'ruby', label: 'Ruby', class: 'ruby'},
			{ language: 'plaintext', label: 'Plain text', class: '' },
			{ language: 'javascript', label: 'JavaScript', class: 'js javascript js-code' },
			{ language: 'python', label: 'Python', class: 'python' },
			{ language: 'bash', label: 'Bash', class: 'bash' }
	]
}

export const wproofreaderConfig = {
	serviceId: process.env.WPROOFREADER_SERVICE_ID,
	srcUrl: process.env.WPROOFREADER_SERVICE_URL
};

export const configuration = {
	extraPlugins: [allowNestedTables],
	list: {
		properties: {
			startIndex: true
		}
	},
	link: {
		defaultProtocol: 'https://'
	},
	licenseKey: process.env.CKE_LICENSE_KEY,
	toolbar: ckeditorToolbar,
	table: ckeditorTable,
	image: ckeditorImage,
	indentBlock: {
		offset: 1,
		unit: 'em'
	},
	fontSize: ckeditorNumericFontSizeConfig,
	language: 'en',
	wproofreader: wproofreaderConfig,
	codeBlock: codeBlockConfig
};

export function allowNestedTables(editor) {
	editor.model.schema.on('checkChild', (evt, args) => {
		const context = args[0];
		const childDefinition = args[1];

		if (context.endsWith('tableCell') && childDefinition && childDefinition.name == 'table') {
			// Prevent next listeners from being called.
			evt.stop();
			// Set the checkChild()'s return value.
			evt.return = true;
		}
	}, {
		priority: 'highest'
	});
};


export const forceEnabledCommandsForTrackChanges = [
	"listStyle",
	"tableBorderColor",
	"tableBorderStyle",
	"tableBorderWidth",
	"tableAlignment",
	"tableWidth",
	"tableHeight",
	"tableBackgroundColor",
	"tableCellBorderStyle",
	"tableCellBorderColor",
	"tableCellBorderWidth",
	'tableCellHorizontalAlignment',
	'tableCellWidth',
	'tableCellHeight',
	'tableCellPadding',
	'tableCellBackgroundColor',
	'tableCellVerticalAlignment',
	'listStart'
]
