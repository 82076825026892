import 'regenerator-runtime/runtime'

const destroyableRefs = new Set();

// Used for the multi-root editor
import { cloneDeep } from 'lodash';

import ClassicEditorBase from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';
import CollaborationEditorWatchdog from '../ckeditor/collaboration_editor_watchdog';
import SingleLineEditor from '../ckeditor/single_line_editor';
import MultirootEditor from '../ckeditor/multiroot_editor';
import MultirootDocumentEditorInitializer from '../ckeditor/initializers/multiroot_document_editor'

import Mention from '@ckeditor/ckeditor5-mention/src/mention';

import {
  forceEnabledCommandsForTrackChanges,
  MentionCustomization,
  ckeditorPluginList,
  ckeditorToolbar,
  ckeditorTable,
  ckeditorImage,
  configuration as defaultConfiguration,
  ckeditorNumericFontSizeConfig,
  headingsConfig
} from '../ckeditor/shared';

import {
  newConfiguration as newDefaultConfiguration,
} from '../ckeditor/v1.2/shared';

class ClassicEditor extends ClassicEditorBase { }

export function getMention(queryText, source) {
  // As an example of an asynchronous action, return a promise
  // that resolves after a 100ms timeout.
  // This can be a server request or any sort of delayed action.
  return new Promise(resolve => {
    setTimeout(() => {
      const itemsToDisplay = source
        // Filter out the full list of all items to only those matching the query text.
        .filter(isItemMatching)
        // Return 10 items max - needed for generic queries when the list may contain hundreds of elements.
        .slice(0, 100);

      resolve(itemsToDisplay);
    }, 100);
  });

  // Filtering function - it uses the `name` and `username` properties of an item to find a match.
  function isItemMatching(item) {
    // Make the search case-insensitive.
    const searchString = queryText.toLowerCase();

    // Include an item in the search results if the name or username includes the current user input.
    return (
      item.label.toLowerCase().includes(searchString) ||
      item.id.toLowerCase().includes(searchString)
    );
  }
}

export function customItemRenderer(item) {
  const buttonElement = document.createElement('button');
  buttonElement.classList.add('ck');
  buttonElement.classList.add('ck-button');
  buttonElement.classList.add('ck-button_with-text')


  const itemElement = document.createElement('span');

  itemElement.classList.add('custom-item');
  itemElement.id = `mention-list-item-id-${item.modelId}`;
  itemElement.textContent = `${item.fullLabel} `;

  const usernameElement = document.createElement('span');

  usernameElement.classList.add('custom-item-badge');
  usernameElement.classList.add(item.type.toLowerCase());
  usernameElement.textContent = item.type;

  itemElement.appendChild(usernameElement);
  buttonElement.appendChild(itemElement);

  return buttonElement;
}

const onTrackChangesEnabled = (editor) => {
  //Force enabling disabled commands
  for (const command of forceEnabledCommandsForTrackChanges) {
    editor.commands.get(command).clearForceDisabled('TrackChangesCommand');
  }
}
export const configureTrackChangesAndSuggestions = (config, editor) => {
  const trackChangesFeatureName = 'trackChanges'
  const trackChangesCommand = editor.commands.get(trackChangesFeatureName);

  trackChangesCommand.on('execute', (eventInfo) => {
    if (eventInfo.source.value) {
      onTrackChangesEnabled(editor);
    }
  })

  if (config.trackChangesOn) {
    editor.execute(trackChangesFeatureName); // turns trackChanges on, and fires `on('execute')`
    trackChangesCommand.forceDisabled('disableTcButton'); // if turned on at the project level, disable the Tc Button

    if (config.disableSuggestion) {
      configureSuggestions(editor);
    }
  }

  // `disableTrackChangesToggle` will always be false unless a user is reviewing the "Accept All Changes"
  // or if the Accept All Changes worker is running.
  // Had to put this separate from the one above because `trackChangesOn` is always false under the same conditions
  if (config.disableTrackChangesToggle) {
    trackChangesCommand.forceDisabled('disableTcButton');
  }
}

export const configureReadOnly = (config, editor) => {
  if (config.readOnlyMode) {
    editor.enableReadOnlyMode('readOnly');
  }
}

export const configureComments = (config, editor) => {
  if (config.disableComments) {
    const comments = editor.commands.get('addCommentThread');
    comments.forceDisabled('comments');
  }
};

const configureSuggestions = (editor) => {
  editor.commands.get('acceptSuggestion').forceDisabled('userNoRights');
  editor.commands.get('acceptAllSuggestions').forceDisabled('userNoRights');
  editor.commands.get('discardSuggestion').forceDisabled('userNoRights');
  editor.commands.get('discardAllSuggestions').forceDisabled('userNoRights');
}

ClassicEditor.builtinPlugins = ckeditorPluginList

window.enableCKEditors = function ($scope) {
  if ($scope instanceof HTMLElement) {
    $scope = $($scope);
  }
  // execute this logic only if the new editor is not present.
  removeDetachedEditors();

  var feeds = [];
  var usersFeeds = [];

  if (window.users) {
    feeds.push({
      marker: '@',
      feed: function (queryText) { return getMention(queryText, window.users) },
    });
    usersFeeds.push({
      marker: '@',
      feed: function (queryText) { return getMention(queryText, window.users) },
    });
  }

  if (window.topics) {
    feeds.push({
      marker: '+',
      feed: function (queryText) { return getMention(queryText, window.topics) },
      itemRenderer: customItemRenderer
    })
  }

  if (window.questions && window.placeholders) {
    feeds.push({
      marker: '$',
      feed: function (queryText) { return getMention(queryText, window.questions.concat(window.placeholders)) },
      // itemRenderer: customItemRenderer
    })
  } else if (window.questions) {
    feeds.push({
      marker: '$',
      feed: function (queryText) { return getMention(queryText, window.questions) },
      // itemRenderer: customItemRenderer
    })
  } else if (window.placeholders) {
    feeds.push({
      marker: '$',
      feed: function (queryText) { return getMention(queryText, window.placeholders) },
      // itemRenderer: customItemRenderer
    })
  }

  if (newSectionEditorFlagDisabled()) {
    $scope.find('.ck-collaboration__editable').each(function (index, element) {
      const showSidebar = element.dataset.showSidebar;
      const watchdog = new CollaborationEditorWatchdog();
      const config = {
        // Add cloud config setup here:
        cloudServices: {
          tokenUrl: $('.ck-collaboration__editable').data('ckeditortokenurl'),
          uploadUrl: $('.ck-collaboration__editable').data('ckeditoruploadurl'),
          webSocketUrl: $('.ck-collaboration__editable').data('ckeditorwebsocketurl'),
          // We set this bundle version number to uniquely identify our bundle and stop users with different builds trying to collaborate.
          // We can set it to any unique string - right now, we are using the current ckeditor version appended with an extra number at the end to signify our internal version -
          // we should bump this version every time we make a change to our ckeditor build or configuration
          bundleVersion: $('.ck-collaboration__editable').data('ckeditorversion')
        },
        collaboration: {
          channelId: $('.ck-collaboration__editable').data('ckeditordocumentid')
        },
        sidebar: {
          container: (showSidebar ? document.querySelector('.ck-collaboration__sidebar') : '')
        },
        presenceList: {
          container: document.querySelector('.ck-collaboration__presence')
        },
        comments: {
          editorConfig: {
            extraPlugins: [Mention, MentionCustomization],
            mention: {
              feeds: usersFeeds
            },
          }
        },
        mention: {
          feeds: feeds
        },
        // Use `trackChangesOn` instead of `trackChanges` to avoid collision with native CKE config option:
        // https://ckeditor.com/docs/ckeditor5/latest/api/module_core_editor_editorconfig-EditorConfig.html#member-trackChanges
        trackChangesOn: $('.ck-collaboration__editable').data('ckeditortrackchangeson'),
        disableSuggestion: $('.ck-collaboration__editable').data('ckeditordisablesuggestion'),
        disableTrackChangesToggle: $('.ck-collaboration__editable').data('ckeditordisabletrackchangestoggle'),
        readOnlyMode: $('.ck-collaboration__editable').data('ckeditorreadonlymode'),
        disableComments: $('.ck-collaboration__editable').data('ckeditordisablecomments'),
        list: {
          properties: {
            startIndex: true
          }
        },
        fontSize: ckeditorNumericFontSizeConfig,
        heading: headingsConfig
      };

      watchdog.create(element, config)
        .then(() => {
          destroyableRefs.add(watchdog);
        });
    });
  }

  const defaultLockID = Symbol('default-readonly-lock');

  $scope.find('textarea.ckeditor').each(function (_, element) {
    if (element.classList.contains('ckeditor--enabled')) return;
    element.classList.add('ckeditor--enabled');
    var $element = $(element);

    const newEditor = document.getElementsByClassName('new-ckeditor');
    let defaultConfig = defaultConfiguration;
    if (newEditor && !element.classList.contains('single-line')) {
      defaultConfig = newDefaultConfiguration;
    }

    const config = cloneDeep(defaultConfig);
    config.cloudServices = {
      tokenUrl: $element.data('ckeditortokenurl'),
      uploadUrl: $element.data('ckeditoruploadurl'),
      webSocketUrl: $element.data('ckeditorwebsocketurl'),
      documentId: $element.data('ckeditordocumentid'),
    };
    config.mention = {
      feeds: feeds,
      dropdownLimit: 20
    };

    const EditorCreator = $(this).hasClass('single-line') ? SingleLineEditor : ClassicEditor;

    EditorCreator.create(element, config)
      .then(editor => {
        // For debugging purposes, this is useful:
        // CKEditorInspector.attach( editor );
        // attach this to the editor so it makes it easier to know which editor we are using when inspecting the DOM
        $(this).attr('data-ckeditorCreator', `textarea-${EditorCreator.name}`)

        // The following fixes an issue where the mentions balloon would sometimes render partly off the screen
        editor.plugins.get('MentionUI')._balloon.view.on('set:left', (evt, propertyName, newValue, oldValue) => {
          const balloonMargin = 20;
          if (newValue < balloonMargin) {
            evt.return = balloonMargin;
          }
          const balloonWidth = $(editor.plugins.get('MentionUI')._balloon.view.element).width();
          const windowWidth = $(window).width();
          const balloonTotalWidth = balloonWidth + newValue;
          if (balloonTotalWidth + balloonMargin > windowWidth) {
            evt.return = windowWidth - balloonWidth - balloonMargin;
          }
        });

        editor.ui.focusTracker.on('change:isFocused', (event, name, isFocused) => {
          if (!isFocused && element.dataset.onchangeevent) {
            const event = new CustomEvent('blur');
            element.dispatchEvent(event);
          }
        })

        editor.model.document.on('change:data', function () {
          const newValue = editor.getData();
          element.innerText = newValue;
          if (element.dataset.onchangeevent) {
            const eventNames = element.dataset.onchangeeventname || 'change';
            let event;
            eventNames.split(' ').forEach(eventName => {
              event = new CustomEvent(eventName);
              element.dispatchEvent(event);
            })
          }
        })

        if (element.classList.contains("readonly")) {
          editor.enableReadOnlyMode(defaultLockID);
        }
        element.dispatchEvent(new CustomEvent('ckeditor:initialized', { bubbles: true, detail: { editor: editor } }))

        destroyableRefs.add(editor);
      })
      .catch(error => {
        console.log(error);
      })
  });

  // Replacing ckeditor multiroot loading with a stimulus controller
  // however, ckmultiroot class is still needed for styling.
  // As multiroot editors are migrated to the stimulus controller, add
  // ckmultiroot--noload class to the element so that it will not be initialized here.
  // To migrate an element to leverage the stimulus controller add
  // data-controller='ckeditor-multiroot' to the element
  $scope.find('div.ckmultiroot:not(.ckmultiroot--noload)').each(function (_, element) {
    var outputArea = $(element).find('textarea').hide()
    var editables = $(element).find(".multiroot-element")
    var sections = {}
    var placeholders = {}
    editables.each(function () {
      sections[$(this).attr('id')] = this
      placeholders[$(this).attr('id')] = $(this).data('placeholder')
    })
    var dataValue = function (editor) {
      var result = ""
      editables.each(function () {
        var currentId = $(this).attr('id')
        // ensure we add appropriate multi header footer column layout classes
        // This will allow the desired layout to save with the ck-editor content
        let multiColumnClassList = $(this).parent().parent()[0].classList; // depends on marginal_field html structure :(
        multiColumnClassList.add('multiroot-column'); // .multiroot-column is used for styling and text processing

        var currentData = editor.getData({
          rootName: currentId
        })
        if (currentData == null || currentData == "") {
          // If we don't put an empty <p> element in here, the columns collapse down when printed
          currentData = "<p></p>"
        }
        result += `<div id="${currentId}" class="${multiColumnClassList.value}">${currentData}</div>`
      })
      return result
    }

    const cloudServicesConfig = {
      tokenUrl: $(element).data('ckeditortokenurl'),
      uploadUrl: $(element).data('ckeditoruploadurl'),
      webSocketUrl: $(element).data('ckeditorwebsocketurl'),
      bundleVersion: $(element).data('ckeditorversion')
    }

    const onEditorContentChange = (editor, outputTextArea) => {
      console.log('[Deprecation Warning] call to onEditorContentChange - migrate to multiroot stimulus controller')
      const newValue = dataValue(editor);
      outputTextArea.val(newValue);
    }

    MultirootEditor
      .create(sections, {
        toolbar: ckeditorToolbar,
        licenseKey: process.env.CKE_LICENSE_KEY,
        image: ckeditorImage,
        table: ckeditorTable,
        placeholder: placeholders,
        cloudServices: cloudServicesConfig,
        collaboration: {
          channelId: $(element).data('ckeditordocumentid')
        },
        mention: {
          feeds: feeds
        },
        list: {
          properties: {
            startIndex: true
          }
        },
        fontSize: ckeditorNumericFontSizeConfig,
        heading: headingsConfig
      })
      .then(newEditor => {
        if (element.classList.contains("readonly")) {
          newEditor.enableReadOnlyMode(defaultLockID);
        }
        $(this).find(".toolbar")[0].appendChild(newEditor.ui.view.toolbar.element);
        newEditor.model.document.on('change:data', function () {
          onEditorContentChange(newEditor, outputArea)
        })
        if ($("select[data-multi-marginal-layout]").length > 0) {
          $("select[data-multi-marginal-layout]").on('change', () => {
            // need to rebuild textarea with appropriate layout
            onEditorContentChange(newEditor, outputArea)
          })
        }

        newEditor.on('destroy', () => newEditor.ui.view.toolbar.element.remove());

        destroyableRefs.add(newEditor);
      })
      .catch(err => {
        console.error(err.stack);
      });
  })
}

$(document).on('turbolinks:load', function () {
  enableCKEditors($('body'));
})

$(document).on('sprinkles:update', function (event) {
  enableCKEditors($(event.target));
})

window.newSectionEditorFlagDisabled = function () {
  return document.querySelectorAll('.new-ckeditor').length === 0;
};

function removeDetachedEditors() {
  for (const reference of destroyableRefs) {
    if (!reference) {
      // Reference is no longer valid.
      destroyableRefs.delete(reference);
    } else {
      // Reference is either watchdog or an editor.
      const editor = reference.editor || reference;

      if (!document.contains(editor.sourceElement)) {
        reference.destroy();
        destroyableRefs.delete(reference);
      }
    }
  }
}
