import BaseEditorController from './base_editor_controller';
import 'regenerator-runtime/runtime'
import { createCKEditor } from '../../account/ckeditor/v1.2/helpers/initializers';

export default class CkEditorController extends BaseEditorController {
  static get targets() {
    return ['editor'];
  }

  editorTargetConnected(element) {
    this.loadCkeditor(element)
  }

  loadCkeditor(element) {
    const { _feeds, _usersFeeds, destroyableRefs } = super.loadFeeds();

    const defaultLockID = Symbol('default-readonly-lock');
    createCKEditor(element, defaultLockID, destroyableRefs);
  }
}
