import EditorUI from '@ckeditor/ckeditor5-ui/src/editorui/editorui';
import normalizeToolbarConfig from '@ckeditor/ckeditor5-ui/src/toolbar/normalizetoolbarconfig';
import { enablePlaceholder } from '@ckeditor/ckeditor5-engine/src/view/placeholder';

/**
 * The multi-root editor UI class.
 *
 * @extends module:core/editor/editorui~EditorUI
 */
export default class MultirootEditorUI extends EditorUI {
  /**
   * Creates an instance of the multi-root editor UI class.
   *
   * @param {module:core/editor/editor~Editor} editor The editor instance.
   * @param {module:ui/editorui/editoruiview~EditorUIView} view The view of the UI.
   */
  constructor(editor, view) {
    super(editor);

    /**
     * The main (top–most) view of the editor UI.
     *
     * @readonly
     * @member {module:ui/editorui/editoruiview~EditorUIView} #view
     */
    this.view = view;

    /**
     * A normalized `config.toolbar` object.
     *
     * @type {Object}
     * @private
     */
    this._toolbarConfig = normalizeToolbarConfig(editor.config.get('toolbar'));
  }

  /**
   * Initializes the UI.
   */
  init() {
    const view = this.view;
    const editor = this.editor;
    const editingView = editor.editing.view;
    let lastFocusedEditableElement;

    view.render();

    this.focusTracker.on('change:focusedElement', (evt, name, focusedElement) => {
      for (const editable of this.view.editables) {
        if (editable.element === focusedElement) {
          lastFocusedEditableElement = editable.element;
        }
      }
    })
    this.focusTracker.on('change:isFocused', (evt, name, isFocused) => {
      if (!isFocused) {
        lastFocusedEditableElement = null;
      }
    });

    this._initPlaceholder();
    this._initToolbar();

    for (const editable of this.view.editables) {
      // The editable UI element in DOM is available for sure only after the editor UI view has been rendered.
      // But it can be available earlier if a DOM element has been passed to DecoupledEditor.create().
      const editableElement = editable._editableElement;

      // Register the editable UI view in the editor. A single editor instance can aggregate multiple
      // editable areas (roots) but the decoupled editor has only one.
      this.setEditableElement('editableName', editableElement);



      editable.bind('isFocused').to(this.focusTracker, 'isFocused', this.focusTracker, 'focusedElement',
        (isFocused, focusedElement) => {

          // When the focus tracker is blurred, it means the focus moved out of the editor UI.
          // No editable will maintain focus then.
          if (!isFocused) {
            return false;
          }

          // If the focus tracker says the editor UI is focused and currently focused element
          // is the editable, then the editable should be visually marked as focused too.
          if (focusedElement === editableElement) {
            return true;
          }
          // If the focus tracker says the editor UI is focused but the focused element is
          // not an editable, it is possible that the editable is still (context–)focused.
          // For instance, the focused element could be an input inside of a balloon attached
          // to the content in the editable. In such case, the editable should remain _visually_
          // focused even though technically the focus is somewhere else. The focus moved from
          // the editable to the input but the focus context remained the same.
          else {
            return lastFocusedEditableElement === editableElement;
          }
        });
      // Bind the editable UI element to the editing view, making it an end– and entry–point
      // of the editor's engine. This is where the engine meets the UI.
      editingView.attachDomRoot(editableElement, editable.name);
    }

    this.fire('ready');
  }

  /**
   * @inheritDoc
   */
  destroy() {
    const view = this.view;
    const editingView = this.editor.editing.view;

    for (const editable of this.view.editables) {
      editingView.detachDomRoot(editable.name);
    }

    view.destroy();

    super.destroy();
  }

  /**
   * Initializes the inline editor toolbar and its panel.
   *
   * @private
   */
  _initToolbar() {
    const editor = this.editor;
    const view = this.view;
    const toolbar = view.toolbar;

    toolbar.fillFromConfig(this._toolbarConfig.items, this.componentFactory);

    this.addToolbar(toolbar);
  }

  /**
   * Enable the placeholder text on the editing root, if any was configured.
   *
   * @private
   */
  _initPlaceholder() {
    const editor = this.editor;
    const editingView = editor.editing.view;

    for (const editable of this.view.editables) {
      const editingRoot = editingView.document.getRoot(editable.name);
      const sourceElement = this.getEditableElement(editable.name);

      const placeholderText = editor.config.get('placeholder')[editable.name] ||
        sourceElement && sourceElement.tagName.toLowerCase() === 'textarea' && sourceElement.getAttribute('placeholder');

      if (placeholderText) {
        editingRoot.placeholder = placeholderText
        enablePlaceholder({
          view: editingView,
          element: editingRoot,
          isDirectHost: false
        });
      }
    }
  }
}