
import RealTimeCollaborativeComments from "@ckeditor/ckeditor5-real-time-collaboration/src/realtimecollaborativecomments";
import RealTimeCollaborativeEditing from "@ckeditor/ckeditor5-real-time-collaboration/src/realtimecollaborativeediting";
import RealTimeCollaborativeTrackChanges from "@ckeditor/ckeditor5-real-time-collaboration/src/realtimecollaborativetrackchanges";
import Comments from '@ckeditor/ckeditor5-comments/src/comments';
import TrackChanges from '@ckeditor/ckeditor5-track-changes/src/trackchanges';

import MultirootEditor from "../multiroot_editor"

import AutoSave from '@ckeditor/ckeditor5-autosave/src/autosave'


import {
  MentionCustomization,
  ckeditorPluginList,
  ckeditorToolbar,
  ckeditorTable,
  ckeditorImage,
  configuration as defaultConfiguration,
  ckeditorNumericFontSizeConfig
} from '../shared';

/**
 * @deprecated
 * I don't believe this is used anywhere...
 * use selector class 'div.ckmultiroot' instead which instantiates a MultirootEditor
 */
export default class MultirootDocumentEditorInitializer {
  static create( parentElement, config = {} ) {

    const $parentElement = $(parentElement)
    var outputArea = $(parentElement).find('textarea')
    var editables = $parentElement.find(".multiroot-document-element")
    var sections = {}
    var placeholders = {}
    const startTime = performance.now()
    parentElement.classList.add("ck-loading")

    editables.each(function() {
      const id = this.dataset.multirootId
      sections[id] = this
      placeholders[id] = $(this).data('placeholder')
      console.log("adding to editables", id)
    })

    if ( editables.length == 0 ) {
      return;
    }

    const cloudServicesConfig = {
      tokenUrl: parentElement.dataset.ckeditortokenurl,
      uploadUrl: parentElement.dataset.ckeditoruploadurl,
      webSocketUrl: parentElement.dataset.ckeditorwebsocketurl,
    }

    MultirootEditor
      .create(sections, {
        fontSize: ckeditorNumericFontSizeConfig,
        list: {
          properties: {
            startIndex: true
          }
        },
        toolbar: ckeditorToolbar,
        licenseKey: process.env.CKE_LICENSE_KEY,
        image: ckeditorImage,
        table: ckeditorTable,
        placeholder: placeholders,
        cloudServices: cloudServicesConfig,
        collaboration: {
          channelId: $(parentElement).data('ckeditordocumentid')
        },
        extraPlugins: [
          RealTimeCollaborativeEditing,
          RealTimeCollaborativeComments,
          RealTimeCollaborativeTrackChanges,
          AutoSave,
          Comments,
          TrackChanges
        ]
      })
      .then(editor => {
        // @note the .toolbar element could be anywhere in the document because we may
        // want a global toolbar that sits outside of the parentElement of this editor
        $(document).find(".toolbar")[0].appendChild(editor.ui.view.toolbar.element);
        editor.on('destroy', () => editor.ui.view.toolbar.element.remove());
        const endTime = performance.now()
        console.log( `Editor initialization took ${ Math.round(endTime - startTime) / 1000 }s` );
        parentElement.classList.remove("ck-loading")
      })
      .catch(err => {
        console.error(err.stack)
      })
  }
}
