import { Controller } from "@hotwired/stimulus";

// For submitting the form when autosaving
import Rails from '@rails/ujs';

// Core editor plus watchdog
import InlineEditor from '@ckeditor/ckeditor5-editor-inline/src/inlineeditor';
import EditorWatchdog from '@ckeditor/ckeditor5-watchdog/src/editorwatchdog';

// Toolbar and basic CKE Plugins
import { toolbar, plugins } from '../imports/document_editor_imports';
import {
  ckeditorTable,
  ckeditorImage
} from '../account/ckeditor/shared';

// Mention plugin and customizer
import Mention from '@ckeditor/ckeditor5-mention/src/mention';
import { ckeditorNumericFontSizeConfig, MentionCustomization, codeBlockConfig } from '../account/ckeditor/shared';

// Helpers for rendering mentions/feeds
import { getMention, customItemRenderer, configureTrackChangesAndSuggestions, configureReadOnly, configureComments } from '../account/fields/ckeditor';

import { waitForElement } from '../utils/utils';

export default class extends Controller {
  connect() {
    this.currentEditor = {};
    this.setFeeds();
  }

  disconnect() {
    if (this.currentEditor.instance) {
      this.currentEditor.instance.destroy();
    }

    this.currentEditor = {};
  }

  setFeeds() {
    this.feeds = [];
    this.usersFeeds = [];

    if (window.users) {
      this.feeds.push({
        marker: '@',
        feed: function (queryText) { return getMention(queryText, window.users) },
      });
      this.usersFeeds.push({
        marker: '@',
        feed: function (queryText) { return getMention(queryText, window.users) },
      });
    }

    if (window.topics) {
      this.feeds.push({
        marker: '+',
        feed: function (queryText) { return getMention(queryText, window.topics) },
        itemRenderer: customItemRenderer
      });
    }

    if (window.questions && window.placeholders) {
      this.feeds.push({
        marker: '$',
        feed: function (queryText) { return getMention(queryText, window.questions.concat(window.placeholders)) },
      });
    } else if (window.questions) {
      this.feeds.push({
        marker: '$',
        feed: function (queryText) { return getMention(queryText, window.questions) }
      });
    } else if (window.placeholders) {
      this.feeds.push({
        marker: '$',
        feed: function (queryText) { return getMention(queryText, window.placeholders) }
      });
    }
  }

  initEditor(event) {
    const sectionId = event.currentTarget.dataset.id;
    const section = document.getElementById(`inline-editor-${sectionId}`);
    const topBar = section.closest('form').querySelector('.document-editor__topbar');
    const autosaveContainer = topBar.querySelector('.document-editor__topbar-autosave');
    const showSidebar = section.dataset.showSidebar;
    const data = section.dataset;
    const sectionContainer = document.querySelector('.section-container');
    this.model = sectionContainer.dataset.model;
    const userDataSelector = (this.model === 'Contracts::Section' ? `div#contracts-section-${sectionId}` : `div#section-${sectionId}`);
    // This is user specific data that is stored on an outer container.
    // It can't be stored on the main editor div because broadcasts via turbo
    // don't have access to current_user context.
    const userData = document.querySelector(userDataSelector).dataset;
    const uneditable = userData.uneditable === 'true';

    event.currentTarget.classList.add('d-none');
    section.classList.remove('d-none');

    // Early return if section is already instantiated as an editor
    // OR user does not have edit permissions OR section is complete
    if ((this.currentEditor.section && (this.currentEditor.section === section)) || uneditable) {
      return;
    } else {
      if (this.currentEditor.instance) {
        this.resetEditor();
      }
    }

    this.showCommentSidebar(showSidebar); // This is for moving the document to the left to make space for the comments

    const watchdog = new EditorWatchdog(InlineEditor);

    watchdog.on('error', (_, { error }) => this.handleWatchdogError(error, section, sectionId));

    this.startSpinner(sectionId);

    watchdog.create(section, {
      toolbar: toolbar,
      plugins: plugins,
      image: ckeditorImage,
      table: ckeditorTable,
      autosave: {
        save: editor => {
          return this.saveData(editor);
        }
      },
      list: {
        properties: {
          startIndex: true
        }
      },
      wproofreader: {
        serviceId: process.env.WPROOFREADER_SERVICE_ID,
        srcUrl: process.env.WPROOFREADER_SERVICE_URL
      },
      licenseKey: process.env.CKE_LICENSE_KEY,
      cloudServices: {
        tokenUrl: userData.ckeditortokenurl,
        uploadUrl: data.ckeditoruploadurl,
        webSocketUrl: data.ckeditorwebsocketurl,
        bundleVersion: data.ckeditorversion
      },
      collaboration: {
        channelId: data.ckeditordocumentid
      },
      sidebar: {
        container: (showSidebar == 'true' ? document.querySelector(`.document-editor__sidebar`) : '')
      },
      presenceList: {
        container: document.getElementById(`presence-list-${sectionId}`)
      },
      comments: {
        editorConfig: {
          extraPlugins: [Mention, MentionCustomization],
          mention: {
            feeds: this.usersFeeds
          },
        }
      },
      mention: {
        feeds: this.feeds
      },
      trackChangesOn: (userData.ckeditortrackchangeson == 'true'),
      disableSuggestion: (userData.ckeditordisablesuggestion === 'true'),
      disableTrackChangesToggle: (userData.ckeditordisabletrackchangestoggle == 'true'),
      readOnlyMode: (userData.ckeditorreadonlymode == 'true'),
      disableComments: (userData.ckeditordisablecomments == 'true'),
      fontSize: ckeditorNumericFontSizeConfig,
      codeBlock: codeBlockConfig
    }).then(() => {
      section.focus();

      const config = watchdog.editor.config._config;
      configureTrackChangesAndSuggestions(config, watchdog.editor);
      configureReadOnly(config, watchdog.editor);
      configureComments(config, watchdog.editor);

      // Update the turbo_frame id so this section does not receive turbo updates while it is an editor
      if (this.model === 'Solicitations::Section') {
        document.getElementById(`section_${sectionId}_body`).id = `section_${sectionId}_body_edit`;
      } else if (this.model === 'Contracts::Section') {
        document.getElementById(`contracts-section-body-${sectionId}`).id = `contracts-section-body-${sectionId}-edit`;
      }
      this.currentEditor = {
        instance: watchdog.editor,
        section: section,
        topBar: topBar,
        autosaveContainer: autosaveContainer
      };

      this.showTopBar();
      this.stopSpinner(sectionId);
    }).catch(e => {
      console.log(e);
    });
  }

  resetEditor() {
    const sectionId = this.currentEditor.section.dataset.id;
    const showSidebar = this.currentEditor.section.dataset.showSidebar;
    const sectionEditor = document.getElementById(`inline-editor-${sectionId}`);
    const sectionView = document.getElementById(`inline-editor-view-${sectionId}`);
    let sectionElement = null;

    this.currentEditor.instance.destroy();
    this.hideCommentSidebar(showSidebar);
    this.hideTopBar();
    // Update the turbo_frame id so this section continues to receive turbo updates after editor is destroyed
    if (this.model === 'Solicitations::Section') {
      sectionElement = document.getElementById(`section_${sectionId}_body_edit`);
      if (sectionElement) {
        sectionElement.id = `section_${sectionId}_body`;
      }
    } else if (this.model === 'Contracts::Section') {
      sectionElement = document.getElementById(`contracts-section-body-${sectionId}-edit`)
      if (sectionElement) {
        sectionElement.id = `contracts-section-body-${sectionId}`;
      }
    }

    sectionView.innerHTML = sectionEditor.innerHTML;

    if (sectionElement) {
      sectionView.classList.remove('d-none');
      sectionEditor.classList.add('d-none');
    }

    this.currentEditor = {};
  }

  async saveData(editor) {
    this.setAutoSaveStatus('saving');
    const data = editor.getData();
    const container = editor.sourceElement;
    const sectionId = container.dataset.id;
    const form = document.getElementById(`ckeditor-autosave-form-${sectionId}`);
    const $bodyInput = $(`#section-body-${sectionId}`);
    const $bodyVersionInput = $(`#body-ckeditor-version-${sectionId}`);
    const $suggestionHighlightedBodyInput = $(`#suggestion-highlighted-body-${sectionId}`);
    const $suggestionAcceptedBodyInput = $(`#suggestion-accepted-body-${sectionId}`);
    const $suggestionRejectedBodyInput = $(`#suggestion-rejected-body-${sectionId}`);

    const acceptedBody = await this.getBodyWithAcceptedSuggestions(editor);
    const rejectedBody = await this.getBodyWithRejectedSuggestions(editor);

    $suggestionAcceptedBodyInput.val(acceptedBody);
    $suggestionRejectedBodyInput.val(rejectedBody);

    // Before submitting the form:
    // Update the hidden input for the body content with the current content in the editor.
    $bodyInput.val(data);
    // Update the hidden input for the body-ckeditor-version with the current version from cke-cloud-services
    $bodyVersionInput.val(editor.plugins.get('RealTimeCollaborationClient').cloudDocumentVersion);
    // Update the hidden input for the suggestion highlighted body content.
    $suggestionHighlightedBodyInput.val(editor.getData({ showSuggestionHighlights: true }));

    return new Promise(resolve => {
      let onSuccess;
      let onError;

      onSuccess = () => {
        this.setAutoSaveStatus('saved');
        // Remove the ajax events we attached to the form. We can't leave these attached because the success
        // callback has to specifically reference the promise that is instantiated for each save attempt.
        $(form).off('ajax:success', onSuccess);
        $(form).off('ajax:error', onError);
        resolve();
      }

      onError = (event) => {
        this.setAutoSaveStatus('offline');
        this.resetEditor();
        const status = event.originalEvent.detail[2].status;

        // Tried to do a save when using an outdated version of ckeditor
        if (status == 426) {
          alert("There has been an update to the Solicitation Builder text editor. We need to refresh the page to load the latest version for you.");
          document.getElementById(`ckeditor-flush-section-${sectionId}`).click();

          // Handle any other errors
        } else {
          alert('There was a problem saving the data for the section. Please refresh the page.');
          // leaving this console.log intentionally to debug any unanticipated errors in QA/Staging
          console.log('AutoSave Error:');
          console.log(event);
        }
      }

      $(form).on('ajax:success', onSuccess);
      $(form).on('ajax:error', onError);

      Rails.fire(form, "submit");
    });
  }

  getBodyWithAcceptedSuggestions(editor) {
    return new Promise(resolve => {
      resolve(editor.plugins.get('TrackChangesData').getDataWithAcceptedSuggestions());
    });
  }

  getBodyWithRejectedSuggestions(editor) {
    return new Promise(resolve => {
      resolve(editor.plugins.get('TrackChangesData').getDataWithDiscardedSuggestions());
    });
  }

  handleSaveBtnClick() {
    this.saveData(this.currentEditor.instance);
  }

  // Find the section editor and programatically click it to trigger the event on that element.
  handleEditIconClick(event) {
    const sectionId = event.currentTarget.closest('.section-container').dataset.id;
    const section = document.getElementById(`inline-editor-view-${sectionId}`);
    section.click();
  }

  // This error is caused by missing cache. We need to reload the section here to ensure we have the latest
  // content from the server. Otherwise, if we allow the editor to restart and the browser has old content,
  // it will overwrite the newer content on the first save.
  //
  // Sends an ajax request to flush the ckeditor cache and refetch the section using a turbo frame.
  handleWatchdogError(error, section, sectionId) {
    if (error.message.match("Document.+does not exist") || error.message.match("cloudservices-reconnection-error")) {
      this.setAutoSaveStatus('offline');
      this.resetEditor();
      const turboFrame = section.closest('turbo-frame')[0];
      alert("This section is out of sync. We need to reload this section to retrieve the latest content.");

      // Make akax request to flush ckeditor cache
      fetch(document.getElementById(`ckeditor-flush-section-${sectionId}`).href, {
        method: 'POST'
      }).then(resp => {
        resp.json();
      }).then(data => {
        // The link was set on a data-src and not a `src` because we didn't want it to make the fetch unless needed
        turboFrame.src = turboFrame.dataset.src;

        // If the editor instantiated somehow, we will want to destroy it
        if (this.currentEditor.instance) {
          this.resetEditor();
        }

        // Get the new section editor content then click to reinstantiate editor.
        turboFrame.reload();
        section.click();
        alert(data.notice);
      }).catch(error => {
        alert('There was a problem fetching the data for the section. Please refresh the page.');
        // leaving this console.log intentionally to debug any unanticipated errors in QA/Staging
        console.log('CKEditor Flush AJAX error:');
        console.log(error);
      });
    } else {
      alert('There was an error while loading the editor. Please refresh the page and try again.');
      // leaving this console.log intentionally to debug any unanticipated errors in QA/Staging
      console.log('WatchDog OnLoad Error:');
      console.log(error);
    }
  }

  editSection(event) {
    const sectionId = event.currentTarget.dataset.id;
    const section = document.getElementById(`inline-editor-${sectionId}`);
    const status = section.dataset.sectionstatus;
    if (status == 'not_started') {
      $.ajax({
        url: `/account/solicitations/sections/${sectionId}/change_section_status`,
        type: 'POST',
        data: { 'status': 'in_progress' },
        dataType: "script",
        success: async function (sectionId) {
          const newSectionBody = await waitForElement(`#section-edit-btn-${sectionId}`);
          if (newSectionBody) { newSectionBody.click(); }
        },
        error: function (error) {
          console.log(error);
        }
      });
    } else {
      this.initEditor(event)
    }
  }

  showTopBar() {
    this.currentEditor.topBar.classList.remove('d-none');
  }

  hideTopBar() {
    this.currentEditor.topBar.classList.add('d-none');
  }

  setAutoSaveStatus(status) {
    const autosaveContainer = this.currentEditor.autosaveContainer;
    const saveButton = autosaveContainer.querySelector('button');
    const icon = autosaveContainer.querySelector('.document-editor__topbar-autosave-icon i');
    const description = autosaveContainer.querySelector('.document-editor__topbar-autosave-description');

    switch (status) {
      case 'saved':
        saveButton.classList.remove('disabled');
        icon.className = 'dripicons-cloud';
        description.innerText = 'All changes saved';
        break;
      case 'saving':
        saveButton.classList.add('disabled');
        icon.className = 'dripicons-cloud-upload';
        description.innerText = 'Saving...';
        break;
      case 'offline':
        saveButton.classList.add('disabled');
        icon.className = 'icon-feather-cloud-off';
        description.innerText = 'Editor Offline';
        break;
    }
  }

  showCommentSidebar(showSidebar) {
    if (showSidebar === 'true') {
      $('#document-container').addClass('document-editor__container');
      $('#document-editor__document').addClass('document-editor__document');
      $('.document-editor__sidebar').removeClass('d-none');
    }
  }

  hideCommentSidebar(showSidebar) {
    if (showSidebar === 'true') {
      $('#document-container').removeClass('document-editor__container');
      $('#document-editor__document').removeClass('document-editor__document');

      $('.document-editor__sidebar').addClass('d-none');
    }
  }

  createSection(event) {
    const documentId = event.currentTarget.dataset.documentid;
    $.ajax({
      url: `/account/solicitations/documents/${documentId}/sections`,
      type: 'POST',
      dataType: "script",
      data: { status_create: 'in_progress' },
      success: async function (sectionId) {
        const newSectionBody = await waitForElement(`#section-edit-btn-${sectionId}`);
        if (newSectionBody) { newSectionBody.click(); }
      },
      error: function (error) {
        console.log(error);
      }
    });
  }

  startSpinner(sectionId) {
    const container = document.getElementById(`spinner-container-${sectionId}`);
    container.classList.remove('d-none');
    container.classList.add('d-flex');
  }

  stopSpinner(sectionId) {
    const container = document.getElementById(`spinner-container-${sectionId}`);
    container.classList.add('d-none');
    container.classList.remove('d-flex');
  }
}
